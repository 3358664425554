<template>
  <div id="dashboard">
    <b-row class="m-0" >
          <b-col sm="12" md="12" lg="4" xl="3">
            <b-row class="m-0">
              <b-col class="card-welcome">
                <h2>
                  Tableau de bord Analytique
                </h2>
                
                <vs-divider color="#cccccc" class="mt-1 mb-1"></vs-divider>
                <h4 class="text-primary">Bonjour{{ userData ? " " + userData.firstName : "" }},</h4>
                <p>Retrouvez dans ce tableau de bord un rapport de votre activité selon la période que vous sélectionnez.</p>
                <vs-divider color="#cccccc" class="m-0 mt-1 mb-50 pl-0 text-primary" position="left">
                  <span class="text-primary pl-0">Période de statistiques</span>
                </vs-divider>
                <date-range-picker
                  ref="picker"
                  id="dates"
                  style="width: 100%"
                  @update="setDates"
                  :show-week-numbers="true"
                  :timePicker="false"
                  :show-dropdowns="true"
                  :opens="'right'"
                  :append-to-body="true"
                  :locale-data="config"
                  :ranges="defaultRange"
                  v-model="statForm.dateRange"
                  :auto-apply="true"
                >
                </date-range-picker>
              </b-col>
            </b-row>
            <b-row style="margin-top: 12px;" class="d-none d-lg-flex">
              <b-col cols="4" class="card-action">
                <b-link :to="'/new-intervention'" class="action-content icon-add-affair">  
                  <div>Créer</br>une <span>intervention</span></div>
                </b-link>
              </b-col>
              <b-col cols="4" class="card-action">
                <b-link :to="'/directory/new-customer'" class="action-content icon-add-collaborator">  
                  <div>Ajouter</br>un <span>client</span></div>
                </b-link>
              </b-col>
              <b-col cols="4" class="card-action">
                <b-link :to="'/new-collaborator'" class="action-content icon-add-collaborator">  
                  <div>Ajouter un</br><span>collaborateur</span></div>
                </b-link>
              </b-col>
              <b-col cols="4" class="card-action">
                <b-link :to="'/new-device'" class="action-content icon-add-device">  
                  <div>Ajouter</br>un <span>appareil</span></div>
                </b-link>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="12" md="12" lg="8" xl="9" s>
              <div class="cart-chart p-0 mt-0" id="statsByDays">
                  <b-row>
                      <b-col md="12" lg="12" xl="8">
                          <div class="content-loader-center m-0" style="height:416px" v-if="isLoadingStatsInterventions">
                            <div class="text-center flex-center">
                              <div class="loading-bg-inner" style="width: 3rem; height: 3rem;">
                                <div class="loader">
                                  <div class="outer"></div>
                                  <div class="middle"></div>
                                  <div class="inner"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <vue-apex-charts v-else height="340" type="bar" :options="statsFromPlanning.chartOptions" :series="statsFromPlanning.series"></vue-apex-charts>
                      </b-col>
                      <b-col  md="12" lg="12" xl="4">
                          <div class="py-2 px-1 row-stats">
                              <div class="card-stats" style="background-color: #0096C7">
                                  <div class="card-stats-title">
                                      <div class="libele">Interventions <span><br></span>planifiées</div>
                                      <div class="number" v-if="isLoadingStatsInterventions" >
                                        <b-spinner class="mr-1" type="grow" variant="light" small></b-spinner><span class="sr-only">Chargement...</span>
                                        <b-spinner class="mr-1" type="grow" variant="light" small></b-spinner><span class="sr-only">Chargement...</span>
                                        <b-spinner class="mr-1" type="grow" variant="light" small></b-spinner><span class="sr-only">Chargement...</span>
                                      </div>
                                      <div class="number" v-else>{{statsInterventions.statInterventionStatusGlobal.numberInterventionStatus[0]}}</div>
                                      
                                  </div>
                              </div>
                              <div class="card-stats" style="background-color: #E3B505">
                                  <div class="card-stats-title">
                                      <div class="libele">Interventions <span><br></span>en cours</div>
                                      <div class="number" v-if="isLoadingStatsInterventions">
                                        <b-spinner class="mr-1" type="grow" variant="light" small></b-spinner><span class="sr-only">Chargement...</span>
                                        <b-spinner class="mr-1" type="grow" variant="light" small></b-spinner><span class="sr-only">Chargement...</span>
                                        <b-spinner class="mr-1" type="grow" variant="light" small></b-spinner><span class="sr-only">Chargement...</span>
                                      </div>
                                       <div class="number" v-else>{{statsInterventions.statInterventionStatusGlobal.numberInterventionStatus[1]}}</div>
                                  </div>
                              </div>
                              <div class="card-stats mb-0" style="background-color: #B4B1B1">
                                  <div class="card-stats-title">
                                      <div class="libele">Interventions <span><br></span>réalisées</div>
                                      <div class="number" v-if="isLoadingStatsInterventions">
                                        <b-spinner class="mr-1" type="grow" variant="light" small></b-spinner><span class="sr-only">Chargement...</span>
                                        <b-spinner class="mr-1" type="grow" variant="light" small></b-spinner><span class="sr-only">Chargement...</span>
                                        <b-spinner class="mr-1" type="grow" variant="light" small></b-spinner><span class="sr-only">Chargement...</span>
                                      </div>                                      
                                      <div class="number" v-else >{{statsInterventions.statInterventionStatusGlobal.numberInterventionStatus[2]}}</div>
                                      
                                  </div>
                              </div>
                          </div>
                      </b-col> 
                  </b-row>
              </div>
              <div>
                  <b-row class="d-flex justify-content-end" style="margin: -12px; ">
                    <b-col  md="5" lg="12" xl="8">
                      <!-- Longitude et Latitude de St Rémy-de-Provence -->
                      <l-map v-if="!isLoadingPoolsList && !isLoadingCustomersList" :zoom="zoom" :center="latLng(43.79117579240926, 4.831517443961531)">
                        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                        <l-marker v-for="pool in poolsList.filter(elem => elem.lat && elem.long)" :key="pool.id" :icon="icon" :lat-lng="latLng(pool.lat, pool.long)">
                          <l-popup style="display: flex; flex-direction: column; align-items: flex-start; width: fit-content">
                            <ul v-if="!hasDuplicates(pool)">
                              <li><span>{{ customersList.find(elem => elem.id == pool.customerId).civility === 0 ? "M." : customersList.find(elem => elem.id == pool.customerId).civility === 1 ? 'Mme.' : customersList.find(elem => elem.id == pool.customerId).civility === 3 ? 'Soc.' : 'Mx.' }}<b class="text-uppercase">{{ customersList.find(elem => elem.id == pool.customerId).lastName }}</b></span></li>
                              <li @click="$router.push({ name: 'pools', params: {poolId: pool.id }})"><span>{{ pool.label }}</span></li>
                            </ul>
                            <ul v-else>
                              <li><span>{{ customersList.find(elem => elem.id == pool.customerId).civility === 0 ? "M." : customersList.find(elem => elem.id == pool.customerId).civility === 1 ? 'Mme.' : customersList.find(elem => elem.id == pool.customerId).civility === 3 ? 'Soc.' : 'Mx.' }}<b class="text-uppercase">{{ customersList.find(elem => elem.id == pool.customerId).lastName  }}</b></span></li>
                              <li v-for="item in getDuplicates(pool)" @click="$router.push({ name: 'pools', params: {poolId: item.id }})" :key="item.id"><span>{{ item.label }}</span></li>
                            </ul>
                          </l-popup>
                        </l-marker>
                      </l-map>
                    </b-col>
                      <b-col md="5" lg="12" xl="4">
                          <div class="cart-chart mb-0 h-100" id="statsbyEventType">
                              <h3  class="mb-0">Répartition par activité</h3>
                              <div class="content-loader-center m-0" style="height:300px" v-if="isLoadingStatsInterventions">
                                <div class="text-center flex-center">
                                  <b-spinner variant="primary" style="width: 3rem; height: 3rem;"></b-spinner><span class="sr-only">Chargement...</span>
                                </div>
                              </div>
                              <div class="content-loader-center m-0" style="height:300px" v-else-if="statsbyEventType.series.reduce((partialSum, a) => partialSum + a, 0) === 0">
                                <div class="text-center column flex-center">
                                  <feather-icon icon="InboxIcon" size="52"/>
                                  <p>Aucune intervention sur cette période.</p>
                                </div>
                              </div>
                              <vue-apex-charts v-else type="donut" :options="statsbyEventType.chartOptions" :series="statsbyEventType.series"></vue-apex-charts>
                          </div>
                      </b-col>
                  </b-row>
              </div>
          </b-col>

    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { BCard, BCardText, BLink } from "bootstrap-vue";
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";
import {
  formatCurrency,
  formatDate,
} from "@/types/api-orisis/library/FormatOperations.ts";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "leaflet/dist/leaflet.css";
import "dayjs/locale/fr";
var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");

dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
const config = {
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  firstDay: 1,
};
const defaultRange = {
  "Ce mois-ci": [
    new Date(dayjs().startOf("month").hour(1)),
    new Date(dayjs().endOf("month")),
  ],
  "Mois dernier": [
    new Date(dayjs().subtract(1, "month").startOf("month").hour(1)),
    new Date(dayjs().subtract(1, "month").endOf("month")),
  ],
  "Ce trimestre": [
    new Date(dayjs().startOf("quarter").hour(1)),
    new Date(dayjs().endOf("quarter")),
  ],
  "Trimestre dernier": [
    new Date(dayjs().subtract(1, "quarter").startOf("quarter").hour(1)),
    new Date(dayjs().subtract(1, "quarter").endOf("quarter")),
  ],
  "Cette année": [
    new Date(dayjs().startOf("year").hour(1)),
    new Date(dayjs().endOf("year")),
  ],
  "Année dernière": [
    new Date(dayjs().subtract(1, "year").startOf("year").hour(1)),
    new Date(dayjs().subtract(1, "year").endOf("year")),
  ],
};

export default {
  components: {
    BLink,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    VueApexCharts,
    DateRangePicker,
  },
  data() {
    return {
      latLng: latLng,
      zoom: 13,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(49.475492, 1.089829),
      icon: L.icon({
        iconUrl: require("@/assets/icones/cmp-pin-map.svg"),
        iconSize: [32, 37],
        iconAnchor: [16, 37],
        popupAnchor: [0, -30],
      }),
      iconSize: 64,
      staticAnchor: [16, 37],

      selectInstitution: null,
      userData: JSON.parse(localStorage.getItem("login"))?.login?.userInfo,
      defaultRange: defaultRange,
      config: config,

      statForm: {
        dateRange: {
          startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
        },
      },

      statsFromPlanning: {
        series: [],
        chartOptions: {
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          xaxis: {
            type: "date",
            categories: [],
          },
          legend: {
            position: "right",
            offsetY: 40,
          },
          fill: {
            opacity: 1,
          },
          colors: [],
        },
      },

      statsbyEventType: {
        series: [],
        chartOptions: {
          chart: {
            type: "donut",
          },
          fill: {
            colors: [],
          },
          labels: [],
          colors: [],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          legend: {
            position: "bottom",
          },
          plotOptions: {
            pie: {
              expandOnClick: false,
            },
          },
          dataLabels: {
            enabled: false,
            formatter: function (val) {
              return val + "J";
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: "Total",
                  value: 122,
                },
              },
            },
          },
        },
      },
    };
  },
  watch: {
    "statForm.dateRange": function () {},
    statsInterventions: function () {
      this.statsFromPlanning.chartOptions.xaxis.categories =
        this.statsInterventions.statsInterventionTypes.datePeriode;

      this.statsFromPlanning.series = [];
      this.statsFromPlanning.chartOptions.colors = [];
    },
  },
  computed: {
    ...mapGetters([
      "statsInterventions",
      "isLoadingStatsInterventions",
      "poolsList",
      "isLoadingPoolsList",
      "customersList",
      "isLoadingCustomersList",
    ]),
  },
  created() {
    this.setDates(this.statForm.dateRange);
    this.fetchPoolsList();
    this.fetchCustomersList();
  },
  methods: {
    ...mapActions([
      "fetchStatsInterventions",
      "fetchPoolsList",
      "fetchCustomersList",
    ]),
    formatCurrency,
    formatDate,
    async setDates(dates) {
      await this.fetchStatsInterventions({
        startDate: new Date(dates.startDate).toISOString(),
        endDate: new Date(dates.endDate).toISOString(),
      }).then((res) => {
        this.$nextTick(() => {
          // Pour le graphique Bar

          this.statsFromPlanning.chartOptions.xaxis.categories =
            res.statsInterventionTypes.datePeriode;

          res.statsInterventionTypes.statsInterventionTypes.forEach((elem) => {
            this.statsFromPlanning.series.push({
              name: elem.name,
              data: elem.data,
            });
            this.statsFromPlanning.chartOptions.colors.push(elem.color);
          });

          // Pour le graphique Donut
          this.statsbyEventType.series =
            res.statInterventionTypeGlobal.numberInterventionType;
          this.statsbyEventType.chartOptions.labels =
            res.statInterventionTypeGlobal.name;
          this.statsbyEventType.chartOptions.colors =
            this.statsbyEventType.chartOptions.fill.colors =
              res.statInterventionTypeGlobal.colors;
        });
      });

      if (dates.startDate != null && dates.endDate != null) {
        this.statForm.startDate = new Date(dates.startDate).toISOString();
        this.statForm.endDate = new Date(dates.endDate).toISOString();
      } else {
        this.statForm.startDate = "";
        this.statForm.endDate = "";
      }
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    hasDuplicates(pool) {
      return (
        this.poolsList.filter(
          (elem) =>
            elem.id !== pool.id &&
            elem.lat === pool.lat &&
            elem.long === pool.long
        ).length > 0
      );
    },
    getDuplicates(pool) {
      return this.poolsList.filter(
        (elem) => elem.lat === pool.lat && elem.long === pool.long
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.leaflet-popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
  span {
    white-space: nowrap;
  }
  li:not(:first-child) {
    background-color: #0096c7;
    color: #fff;
    padding: 5px 10px;
    margin-top: 5px;
    border-radius: 8px;
    cursor: pointer;
  }
}
</style>

